import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section>
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="9" xl="8">
                                <h1 variant="hero" className="text-center">
                                    Terms and Conditions for MiniDukan (Private) Limited
                                </h1>
                                <h3>Introduction</h3>

                                <p>These Website/App Standard Terms and Conditions written on this webpage shall manage your use of our website, MiniDukan accessible at https://www.minidukan.com.</p>
                                <p>These Terms will be applied fully and affect to your use of this Website/App. By using this Website/App, you agreed to accept all terms and conditions written in here. You must not use this Website/App if you disagree with any of these Website/App Standard Terms and Conditions. </p>
                                <p>Minors or people below 18 years old are not allowed to use this Website/App.</p>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Restrictions</h3>

                                    <p>You are specifically restricted from all of the following:</p>

                                    <List>
                                        <li>publishing any Website/App material in any other media;</li>
                                        <li>selling, sublicensing and/or otherwise commercializing any Website/App material;</li>
                                        <li>publicly performing and/or showing any Website/App material;</li>
                                        <li>using this Website/App in any way that is or may be damaging to this Website/App;</li>
                                        <li>using this Website/App in any way that impacts user access to this Website/App;</li>
                                        <li>using this Website/App contrary to applicable laws and regulations, or in any way may cause harm to the Website/App, or to any person or business entity;</li>
                                        <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website/App;</li>
                                    </List>
                                </Box>

                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Your Content</h3>

                                    <p>In these Website/App Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website/App. By displaying Your Content, you grant MiniDukan (Private) Limited a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

                                    <p>Your Content must be your own and must not be invading any third-party’s rights. MiniDukan (Private) Limited reserves the right to remove any of Your Content from this Website/App at any time without notice.</p>
                                </Box>

                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Your Privacy</h3>

                                    <p>Please read Privacy Policy.</p>
                                </Box>

                                <Box mt={["40px", null, "50px"]}>
                                    <h3>No warranties</h3>

                                    <p>This Website/App is provided "as is," with all faults, and MiniDukan (Private) Limited express no representations or warranties, of any kind related to this Website/App or the materials contained on this Website/App. Also, nothing contained on this Website/App shall be interpreted as advising you.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Limitation of liability</h3>

                                    <p>In no event shall MiniDukan (Private) Limited, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website/App whether such liability is under contract. MiniDukan (Private) Limited, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website/App.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Indemnification</h3>

                                    <p>You hereby indemnify to the fullest extent MiniDukan (Private) Limited from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Severability</h3>

                                    <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Variation of Terms</h3>

                                    <p>MiniDukan (Private) Limited is permitted to revise these Terms at any time as it sees fit, and by using this Website/App you are expected to review these Terms on a regular basis.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Assignment</h3>

                                    <p>The MiniDukan (Private) Limited is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Entire Agreement</h3>

                                    <p>These Terms constitute the entire agreement between MiniDukan (Private) Limited and you in relation to your use of this Website/App, and supersede all prior agreements and understandings.</p>
                                </Box>
                                <Box mt={["40px", null, "50px"]}>
                                    <h3>Governing Law & Jurisdiction</h3>

                                    <p>These Terms will be governed by and interpreted in accordance with the laws of the State of pk, and you submit to the non-exclusive jurisdiction of the state and federal courts located in pk for the resolution of any disputes.</p>
                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default TermsConditions;
